import { PureComponent } from "react";
import { Dimen } from "../model/Dimen";
import { State } from "../model/State";
import { ViewModel } from "../model/ViewModel";
import { ContentImageLink } from "./content/ContentImageLink";
import { ContentTextLink } from "./content/ContentTextLink";
import { StreakIcon } from "./StreakIcon";

export class HeaderProps {
    contentMode: State.ContentMode;
    headerMode: State.HeaderMode;
    locale: State.Locale;
    onClickHomeButton: () => void;
    onClickSupportButton: () => void;
    onClickPrivacyButton: () => void;
    onClickAppStoreButton: () => void;

    constructor(
        contentMode: State.ContentMode,
        headerMode: State.HeaderMode,
        locale: State.Locale,
        onClickHomeButton: () => void,
        onClickSupportButton: () => void,
        onClickPrivacyButton: () => void,
        onClickAppStoreButton: () => void
    ) {
        this.contentMode = contentMode;
        this.headerMode = headerMode;
        this.locale = locale;
        this.onClickHomeButton = onClickHomeButton;
        this.onClickSupportButton = onClickSupportButton;
        this.onClickPrivacyButton = onClickPrivacyButton;
        this.onClickAppStoreButton = onClickAppStoreButton;
    }
}

export class Header extends PureComponent<HeaderProps> {
  render = () => {
    return <div
      id={ViewModel.Identifier.header}
      style={{
        ...this.styleForHeader(),
        boxSizing: "border-box"
      }}
    >
      {this.contentForMode()}
    </div>
  }

  styleForHeader = () => {
    switch (this.props.headerMode) {
      case State.HeaderMode.normal: return ViewModel.Header.headerModeStyleNormal;
      case State.HeaderMode.sticky: return ViewModel.Header.headerModeStyleSticky;
    }
  }

  contentForMode = () => {
    switch (this.props.contentMode) {
      case State.ContentMode.desktop: return this.contentForDesktopMode();
      case State.ContentMode.tablet: return this.contentForTabletMode();
      case State.ContentMode.mobile: return this.contentForMobileMode();
    }
  }

  contentForDesktopMode = () => {
    return <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }}
    >
      <StreakIcon
        size={ViewModel.Header.streakIconSize}
        center={false}
        onClickStreakIcon={this.props.onClickHomeButton}
      />
      <div style={{
        display: "flex",
        alignItems: "center"
      }}>
        <div style={Dimen.Margin.right(Dimen.Margin.contentSectionSubtitle)}>
          <SupportButton
            locale={this.props.locale}
            onClick={this.props.onClickSupportButton}
          />
        </div>
        <div style={Dimen.Margin.right(Dimen.Margin.contentSectionSubtitle)}>
          <PrivacyButton
            locale={this.props.locale}
            onClick={this.props.onClickPrivacyButton}
          />
        </div>
        <AppStoreIconLink
          onClick={this.props.onClickAppStoreButton}
        />
      </div>
    </div>;
  }

  contentForTabletMode = () => {
    return <div><div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <StreakIcon
          size={ViewModel.Header.streakIconSize}
          center={false}
          onClickStreakIcon={this.props.onClickHomeButton}
        />
        <div
        style={{
          display: "flex-column",
          alignItems: "center"
        }}>
          <SupportButton
            locale={this.props.locale}
            onClick={this.props.onClickSupportButton}
          />
          <div style={Dimen.Margin.top(Dimen.Spacer.ofSize(Dimen.Size._03))}>
            <PrivacyButton
              locale={this.props.locale}
              onClick={this.props.onClickPrivacyButton}
            />
          </div>
        </div>
        <AppStoreIconLink
          onClick={this.props.onClickAppStoreButton}
        />
      </div></div>;
  }

  contentForMobileMode = () => {
    return <div><div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <StreakIcon
          size={ViewModel.Header.streakIconSize}
          center={false}
          onClickStreakIcon={this.props.onClickHomeButton}
        />
        <AppStoreIconLink
          onClick={this.props.onClickAppStoreButton}
        />
      </div>
      <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        ...Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)
      }}>
        <div style={{
          display: "flex",
          alignItems: "center"
        }}>
          <div style={Dimen.Margin.right(Dimen.Margin.contentSectionTitle)}>
          <SupportButton
            locale={this.props.locale}
            onClick={this.props.onClickSupportButton}
          />
          </div>
          <PrivacyButton
            locale={this.props.locale}
            onClick={this.props.onClickPrivacyButton}
          />
        </div>
      </div></div>;
  }

}

class HeaderButtonProps {
  onClick: () => void;
  constructor(onClick: () => void) {
    this.onClick = onClick;
  }
}

class HeaderTextButtonProps extends HeaderButtonProps {
  locale: State.Locale;
  constructor(locale: State.Locale, onClick: () => void) {
    super(onClick);
    this.locale = locale;
  }
}

class SupportButton extends PureComponent<HeaderTextButtonProps> {
  render = () => {
    return <ContentTextLink 
      contentText={ViewModel.Header.contentTextSupportButton}
      locale={this.props.locale}
      onClick={this.props.onClick}
    />
  }
}

class PrivacyButton extends PureComponent<HeaderTextButtonProps> {
  render = () => {
    return <ContentTextLink 
      contentText={ViewModel.Header.contentTextPrivacyButton}
      locale={this.props.locale}
      onClick={this.props.onClick}
    />
  }
}

class AppStoreIconLink extends PureComponent<HeaderButtonProps> {
  render = () => {
    return <ContentImageLink 
      image={ViewModel.Header.contentImageAppStoreButton}
      onClick={this.props.onClick}
    />
  }
}