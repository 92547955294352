import React from "react";
import { Content } from "../../model/Content";
import { State } from "../../model/State";
import { TextValues } from "../../model/TextValues";

export class ContentVideoProps {
  video: Content.Video;
  locale: State.Locale

  constructor(
    contentVideo: Content.Video,
    locale: State.Locale
  ) {
    this.video = contentVideo;
    this.locale = locale;
  }
}

export class ContentVideo extends React.PureComponent<ContentVideoProps> {
  render = () => {
    return <div style={{
        ...this.props.video.styleContainer
      }}>
        <video 
        style={this.props.video.style}
        controls
        autoPlay
        loop
      >
        <source 
          src={this.props.video.source}
          type="video/mp4"
        />
        {TextValues.Error.video}
      </video>
    </div>
  }
}