import { PureComponent } from "react";
import { State } from "../model/State";
import { ViewModel } from "../model/ViewModel";
import { ContentSectionTextVideo } from "./content/ContentSectionTextVideo";

export class Home04GoStreakingProps {
  contentMode: State.ContentMode;
  locale: State.Locale;

  constructor(
    contentMode: State.ContentMode,
    locale: State.Locale
  ) {
    this.contentMode = contentMode;
    this.locale = locale;
  }
}

export class Home04GoStreaking extends PureComponent<Home04GoStreakingProps> {
  render = () => {
    return <ContentSectionTextVideo
      contentSectionTextVideo={ViewModel.Home04GoStreaking.contentSectionTextVideo(this.props.contentMode)}
      locale={this.props.locale}
      contentMode={this.props.contentMode}
    />
  }
}