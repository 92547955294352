import image_streak_icon from "../assets/streak_app_icon.png";
import image_streak_icon_app_store from "../assets/streak_app_icon_app_store.svg";

import video_home_01 from '../assets/streak_app_video_home_01_welcome.mp4'
import video_home_02 from '../assets/streak_app_video_home_02_add_daylies.mp4'
import video_home_03 from '../assets/streak_app_video_home_03_make_dayley_entries.mp4'
import video_home_04 from '../assets/streak_app_video_home_04_go_streaking.mp4'

export default {
    image: {
        streakIcon: image_streak_icon,
        streakIconAppStore: image_streak_icon_app_store
    },
    video: {
        home01: video_home_01,
        home02: video_home_02,
        home03: video_home_03,
        home04: video_home_04
    }
}