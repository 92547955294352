import React from "react";
import { ContentText } from "./content/ContentText";
import { ContentTextLink } from "./content/ContentTextLink";
import { Dimen } from "../model/Dimen";
import { State } from "../model/State";
import { ViewModel } from "../model/ViewModel";

export class SupportProps {
  contentMode: State.ContentMode;
  locale: State.Locale;
  onClickSupportButton: () => void;

  constructor(
    contentMode: State.ContentMode,
    locale: State.Locale,
    onClickSupportButton: () => void
  ) {
    this.contentMode = contentMode;
    this.locale = locale;
    this.onClickSupportButton = onClickSupportButton;
  }
}

export class Support extends React.PureComponent<SupportProps> {
  render = () => {
    return <div>
        {this.contentForMode()}
      </div>;
  }

  contentForMode = () => {
    switch (this.props.contentMode) {
      case State.ContentMode.desktop: return this.contentForDesktopMode();
      case State.ContentMode.tablet: return this.contentForTabletMode();
      case State.ContentMode.mobile: return this.contentForMobileMode();
    }
  }

  contentForDesktopMode = () => {
    return <div>
      <ContentText 
        contentText={ViewModel.Support.titleText(State.ContentMode.desktop)}
        locale={this.props.locale}
        id={ViewModel.Identifier.support}
      />
      <div style={{ 
        display: "flex",
        alignItems: "baseline"
      }}>
        <ContentText 
          contentText={
            ViewModel.Support.subtitleText(State.ContentMode.desktop)
          }
          locale={this.props.locale}
        />
        <div style={Dimen.Margin.left(Dimen.Spacer.ofSize(Dimen.Size._04))}>
          <ContentTextLink 
            contentText={ViewModel.Support.linkText(State.ContentMode.desktop)}
            locale={this.props.locale}
            onClick={this.props.onClickSupportButton}
          />
        </div>
      </div>
    </div>
  }

  contentForTabletMode = () => {
    return this.contentForDesktopMode();
  }

  contentForMobileMode = () => {
    return <div>
      <ContentText 
        contentText={ViewModel.Support.titleText(State.ContentMode.mobile)}
        locale={this.props.locale}
        id={ViewModel.Identifier.support}
      />
      <div style={Dimen.Margin.top(Dimen.Spacer.zero)}>
        <ContentText 
          contentText={ViewModel.Support.subtitleText(State.ContentMode.mobile)}
          locale={this.props.locale}
        />
      </div>
      <div style={Dimen.Margin.top(Dimen.Spacer.ofSize(Dimen.Size._02))}>
        <ContentTextLink 
          contentText={ViewModel.Support.linkText(State.ContentMode.mobile)}
          locale={this.props.locale}
          onClick={this.props.onClickSupportButton}
        />
      </div>
    </div>
  }
}