import { Content } from "./Content";
import { Dimen } from "./Dimen";
import { Font } from "./Font";
import { TextValues } from "./TextValues";
import { Color } from "./Color";
import { State } from "./State";
import { LocalizedText } from "./LocalizedText";
import ContentImport from "./ContentImport";

export namespace ViewModel {
  export enum Event {
    resize = "resize",
    scroll = "scroll"
  }
  export enum Identifier {
    root="root",
    header = "header",
    home = "home",
    support = "support",
    privacy = "privacy"
  }

  export namespace ImageSource {
    export const streakIcon = ContentImport.image.streakIcon;
    export const streakIconAppStore = ContentImport.image.streakIconAppStore;
  }

  export namespace VideoSource {
    export const home01 = ContentImport.video.home01;
    export const home02 = ContentImport.video.home02;
    export const home03 = ContentImport.video.home03;
    export const home04 = ContentImport.video.home04;
  }

  export namespace Dimens {
    export const cornerRadiusImagesAndVideos = Dimen.CornerRadius.ofSize(Dimen.Size._04);
    export const headerStreakIcon = 88;
    export const videoWidthForContentMode = (contentMode: State.ContentMode) => {
      switch (contentMode) {
        case State.ContentMode.desktop: return videoWidthDesktop;
        case State.ContentMode.tablet: return videoWidthTablet;
        case State.ContentMode.mobile: return videoWidthMobile;
      }
    };
    const videoWidthMobile = 180;
    const videoWidthTablet = 240;
    const videoWidthDesktop = 320 - 2 * Dimen.Margin.content.value;
  }
  
  export namespace Fonts {
    export const contentSectionTitle: Font = new Font(
      Color.blue,
      Font.Family.Oswald,
      Dimen.Size._05,
      Font.Weight.normal
    );
    export const contentSectionSubtitle: Font = new Font(
      Color.grayDark,
      Font.Family.OpenSansCondensed,
      Dimen.Size._04,
      Font.Weight.normal
    );
    export const footerCopyright: Font = new Font(
      Color.gray,
      Font.Family.OpenSans,
      Dimen.Size._01, // 12
      Font.Weight.bold
    ); 
    export const footerLocation: Font = new Font(
      Color.gray,
      Font.Family.OpenSans,
      Dimen.Size._01, // 12
      Font.Weight.normal
    ); 
    export const textButton: Font = new Font(
      Color.grayDark,
      Font.Family.OpenSans,
      Dimen.Size._02, // 16
      Font.Weight.normal
    );
  }

  export namespace ContentSection {
    export const contentTitleForMode = (contentMode: State.ContentMode, text: LocalizedText) => {
      return new Content.Text(
        Dimen.CornerRadius.zero,
        {},
        ViewModel.Fonts.contentSectionTitle,
        text,
        contentMode == State.ContentMode.mobile
      );
    }
    export const contentSubtitleForMode = (contentMode: State.ContentMode, text: LocalizedText) => {
      return new Content.Text(
        Dimen.CornerRadius.zero,
        {},
        ViewModel.Fonts.contentSectionSubtitle,
        text,
        contentMode == State.ContentMode.mobile
      );
    }
  }

  export class ContentSectionText {
    title: Content.Text;
    subtitles: Content.Text[];
    constructor(
      title: LocalizedText,
      subtitles: LocalizedText[],
      contentMode: State.ContentMode
    ) {
      this.title = ViewModel.ContentSection.contentTitleForMode(contentMode, title);
      this.subtitles = subtitles.map(subtitle => 
        ViewModel.ContentSection.contentSubtitleForMode(contentMode, subtitle)
      );
    }
  }

  export class ContentSectionTextVideo {
    textSection: ViewModel.ContentSectionText;
    video: Content.Video;
    constructor(
      title: LocalizedText,
      subtitles: LocalizedText[],
      contentMode: State.ContentMode,
      videoSource: any,
      videoCornerRadius: Dimen.CornerRadius,
      videoMaxWidth?: number,
      videoMaxHeight?: number,
      videoStyle?: {}
    ) {
      this.textSection = new ViewModel.ContentSectionText(
        title,
        subtitles,
        contentMode
      )
      this.video = new Content.Video(
        videoCornerRadius,
        videoSource,
        videoStyle ?? {},
        contentMode == State.ContentMode.mobile,
        videoMaxWidth,
        videoMaxHeight
      )
    }
  }

  export namespace Header {
    const headerModeStyleConstant = {
      backgroundColor: Color.white.value,
      width: "100%",
      zIndex: 4,
      ...Dimen.Padding.all(Dimen.Padding.contentSectionSubtitle)
    };
    export const headerModeStyleNormal = {
      ...headerModeStyleConstant,
      top: -100
    };
    export const headerModeStyleSticky = {
      ...headerModeStyleConstant,
      top: 0,
      position: "fixed",
      transition: "top 0.5s ease-in-out",
      boxShadow: "0px 0.5px 0px grey"
    };
    export const contentImageAppStoreButton = new Content.Image(
      Dimen.CornerRadius.zero,
      ImageSource.streakIconAppStore,
      {},
      false,
      undefined
    );
    export const contentTextSupportButton = new Content.Text(
      Dimen.CornerRadius.zero,
      {},
      ViewModel.Fonts.textButton,
      TextValues.Support.title,
      false
    );
    export const contentTextPrivacyButton = new Content.Text(
      Dimen.CornerRadius.zero,
      {},
      ViewModel.Fonts.textButton,
      TextValues.Privacy.title,
      false
    );
    export const streakIconSize = 88;
  }

  export namespace Home01Welcome {
    export const contentSectionTextVideo = (contentMode: State.ContentMode) => {
      return new ViewModel.ContentSectionTextVideo(
        TextValues.Home.title01Welcome,
        TextValues.Home.subtitles01Welcome,
        contentMode,
        ViewModel.VideoSource.home01,
        ViewModel.Dimens.cornerRadiusImagesAndVideos,
        ViewModel.Dimens.videoWidthForContentMode(contentMode),
        undefined,
        undefined
      )
    }
  }

  export namespace Home02AddDaylies {
    export const contentSectionTextVideo = (contentMode: State.ContentMode) => {
      return new ViewModel.ContentSectionTextVideo(
        TextValues.Home.title02AddDaylies,
        TextValues.Home.subtitles02AddDaylies,
        contentMode,
        ViewModel.VideoSource.home02,
        ViewModel.Dimens.cornerRadiusImagesAndVideos,
        ViewModel.Dimens.videoWidthForContentMode(contentMode),
        undefined,
        undefined
      )
    }
  }

  export namespace Home03MakeDayleyEntries {
    export const contentSectionTextVideo = (contentMode: State.ContentMode) => {
      return new ViewModel.ContentSectionTextVideo(
        TextValues.Home.title03MakeDayleyEntries,
        TextValues.Home.subtitles03MakeDayleyEntries,
        contentMode,
        ViewModel.VideoSource.home03,
        ViewModel.Dimens.cornerRadiusImagesAndVideos,
        ViewModel.Dimens.videoWidthForContentMode(contentMode),
        undefined,
        undefined
      )
    }
  }

  export namespace Home04GoStreaking {
    export const contentSectionTextVideo = (contentMode: State.ContentMode) => {
      return new ViewModel.ContentSectionTextVideo(
        TextValues.Home.title04GoStreaking,
        TextValues.Home.subtitles04GoStreaking,
        contentMode,
        ViewModel.VideoSource.home04,
        ViewModel.Dimens.cornerRadiusImagesAndVideos,
        ViewModel.Dimens.videoWidthForContentMode(contentMode),
        undefined,
        undefined
      )
    }
  }

  export namespace Support {
    export const titleText = (contentMode: State.ContentMode) => {
      return ViewModel.ContentSection.contentTitleForMode(
        contentMode,
        TextValues.Support.title
      );
    }
    export const subtitleText = (contentMode: State.ContentMode) => {
      return ViewModel.ContentSection.contentSubtitleForMode(
        contentMode,
        TextValues.Support.subtitle
      );
    }
    export const linkText = (contentMode: State.ContentMode) => {
      return new Content.Text(
        Dimen.CornerRadius.zero,
        {},
        ViewModel.Fonts.textButton,
        TextValues.Support.link,
        contentMode != State.ContentMode.desktop
      );
    }
  }

  export namespace Privacy {
    export const content = (contentMode: State.ContentMode) => {
      return new ContentSectionText(
        TextValues.Privacy.title,
        TextValues.Privacy.subtitles,
        contentMode
      )
    }
  }

  export namespace Footer {
    export const copyrightText: Content.Text = new Content.Text(
      Dimen.CornerRadius.zero,
      {},
      ViewModel.Fonts.footerCopyright,
      TextValues.Footer.copyright,
      true
    );
    export const locationText: Content.Text = new Content.Text(
      Dimen.CornerRadius.zero,
      Dimen.Margin.top(Dimen.Spacer.ofSize(Dimen.Size._01)),
      ViewModel.Fonts.footerLocation,
      TextValues.Footer.location,
      true
    );
  }
}