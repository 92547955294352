import { PureComponent } from "react";
import { State } from "../model/State";
import { ViewModel } from "../model/ViewModel";
import { ContentSectionTextVideo } from "./content/ContentSectionTextVideo";

export class Home02AddDayliesProps {
    contentMode: State.ContentMode;
    locale: State.Locale;

    constructor(
        contentMode: State.ContentMode,
        locale: State.Locale
    ) {
        this.contentMode = contentMode;
        this.locale = locale;
    }
}

export class Home02AddDaylies extends PureComponent<Home02AddDayliesProps> {
    render = () => {
        return <ContentSectionTextVideo
            contentSectionTextVideo={ViewModel.Home02AddDaylies.contentSectionTextVideo(this.props.contentMode)}
            locale={this.props.locale}
            contentMode={this.props.contentMode}
        />
    }
}