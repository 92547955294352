import { LocalizedText } from "./LocalizedText";

export namespace TextValues {
  export namespace Error {
    export const video = "Video not supported";
  }

  export namespace Home {
    export const title01Welcome: LocalizedText = new LocalizedText("streak");
    export const subtitles01Welcome: LocalizedText[] = [
      new LocalizedText("tracks your goals and day-to-day tasks - your DAYLIES."),
      new LocalizedText("Streak provides flexibility and an intuitive UI, making it easy to get things done."),
      new LocalizedText("There are no ads or subscriptions, just a simple, powerful tool to make completing your DAYLIES a little easier.")
    ];

    export const title02AddDaylies: LocalizedText = new LocalizedText("MAKE DAYLIES");
    export const subtitles02AddDaylies: LocalizedText[] = [
      new LocalizedText("Select TIME PERIOD in number of days, weeks, or months."),
      new LocalizedText("Add CATEGORIES to track numbers."),
      new LocalizedText("CATEGORIES are optional by default."),
      new LocalizedText("CATEGORIES can have a REQUIRED CONDITION and VALUE,"),
      new LocalizedText("eg minimum 10 Running (miles) per STREAK."),
      new LocalizedText("Setting these will make STREAKs dependent on the DAYLEY's CATEGORY conditions.")
    ];

    export const title03MakeDayleyEntries: LocalizedText = new LocalizedText("MAKE DAYLEY ENTRIES");
    export const subtitles03MakeDayleyEntries: LocalizedText[] = [
      new LocalizedText("DAYLIES have one ENTRY per day."),
      new LocalizedText("ENTRIES can be completed in one of three ways, depending on DAYLEY conditions:"),
      new LocalizedText("(1) Press the check button (with no required CATEGORIES)"),
      new LocalizedText("(2) Entering CATEGORY values, eg Running (miles) > 3.00"),
      new LocalizedText("(3) Write a NOTE")
    ];

    export const title04GoStreaking: LocalizedText = new LocalizedText("GO STREAKING");
    export const subtitles04GoStreaking: LocalizedText[] = [
      new LocalizedText("STREAKs are created by meeting a DAYLEY's conditions in a time interval."),
      new LocalizedText("A DAYLEY's STREAK conditions can be either"),
      new LocalizedText("(1) creating the required number of ENTRIES,"),
      new LocalizedText("or (2) meeting all of its CATEGORY requirements."),
      new LocalizedText("Keep your STREAK going by completing the next interval!"),
      new LocalizedText("Export your data as a .csv to use anywhere.")
    ];
  }
  
  export namespace Support {
    export const title: LocalizedText = new LocalizedText("SUPPORT")
    export const subtitle: LocalizedText = new LocalizedText("Questions, feedback, feature requests?");
    export const link: LocalizedText = new LocalizedText("Get in touch!");
  }
  
  export namespace Privacy {
    export const title: LocalizedText = new LocalizedText("PRIVACY")
    export const subtitles: LocalizedText[] = [
      new LocalizedText("STREAK does not use or store any personal identifying information."),
      new LocalizedText("All of STREAK's app data is stored on the local device."),
      new LocalizedText("STREAK currently has no network connectivity - no tracking, no analytics, no \"phone home\" to server, etc."),
      new LocalizedText("The only way any data leaves the App is if the user exports it as a csv."),
      new LocalizedText("Future updates might add network functionality, and if so, this privacy policy and App Store description will be updated."),
      new LocalizedText("Thanks for purchasing and know that STREAK respects and protects your privacy.")
    ];
  }
  
  export namespace Footer {
    export const copyright: LocalizedText = new LocalizedText("© 2021 Jared Bourgeois");
    export const location: LocalizedText = new LocalizedText("Baton Rouge, Louisiana");
  }  
}
