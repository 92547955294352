export namespace State {
  export enum ContentMode {
    desktop = "desktop",
    tablet = "tablet",
    mobile = "mobile"
  }
  export namespace ContentMode {
    const minWidthDesktop = 750;
    const minWidthTablet = 500;
    
    export const forWindowWidth = (width: number) => {
      return width >= minWidthDesktop ? ContentMode.desktop : width >= minWidthTablet ? ContentMode.tablet : ContentMode.mobile;
    }
  }
  
  export enum HeaderMode {
    normal,
    sticky
  }

  export enum Locale {
    english
  }
  export namespace Locale {
    export const forLocale = (locale: string) => {
      switch (locale) {
      default: return Locale.english;
      }
    }
  }

  export class Value {
    contentMode: ContentMode;
    headerMode: HeaderMode;
    locale: Locale;
  
    constructor(
      contentMode: ContentMode = ContentMode.desktop,
      headerMode: HeaderMode = HeaderMode.normal,
      locale: Locale = Locale.english
    ) {
      this.contentMode = contentMode;
      this.headerMode = headerMode;
      this.locale = locale;
    }
  
    updateForWindowWidth = (width: number) => {
      const contentMode = ContentMode.forWindowWidth(width);
      return contentMode != this.contentMode ? new Value(ContentMode.forWindowWidth(width), this.headerMode) : this;
    }
  
    updateHeaderMode = (pageYOffset: number, headerOffsetHeight: number) => {
      const headerMode = pageYOffset >= headerOffsetHeight ? HeaderMode.sticky : HeaderMode.normal
      return headerMode != this.headerMode ? new Value(this.contentMode, headerMode) : this;
    }
  }
}
