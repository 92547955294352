import React from "react";
import { State } from "../model/State";
import { ViewModel } from "../model/ViewModel";
import { ContentText } from "./content/ContentText";
import { ContentTextLink } from "./content/ContentTextLink";

export class FooterProps {
  contentMode: State.ContentMode;
  locale: State.Locale;
  onClickCopyrightButton: () => void;

  constructor(
    contentMode: State.ContentMode,
    locale: State.Locale,
    onClickCopyrightButton: () => void
  ) {
    this.contentMode = contentMode;
    this.locale = locale;
    this.onClickCopyrightButton = onClickCopyrightButton
  }
}

export class Footer extends React.PureComponent<FooterProps> {
  render = () => {
    return <div>
      <ContentTextLink 
        contentText={ViewModel.Footer.copyrightText}
        locale={this.props.locale}
        onClick={this.props.onClickCopyrightButton}
      />
      <ContentText 
        contentText={ViewModel.Footer.locationText}
        locale={this.props.locale}
      />
    </div>
  }
}