import React from "react";
import { Content } from "../../model/Content";
import { ContentImage } from "./ContentImage";

export class ContentImageLinkProps {
  image: Content.Image;
  onClick: () => void;

  constructor(
    contentImage: Content.Image,
    onClick: () => void
  ) {
    this.image = contentImage;
    this.onClick = onClick;
  }
}

export class ContentImageLinkState {
    showOverlay: boolean

    constructor(showOverlay: boolean) {
        this.showOverlay = showOverlay;
    }
}

export class ContentImageLink extends React.Component<ContentImageLinkProps, ContentImageLinkState> {
  state = new ContentImageLinkState(false);
  
  render = () => {
    return <div 
      style={this.styleForState()}
      onMouseOver={this.onMouseOver}
      onMouseLeave={this.onMouseLeave}
      onClick={this.props.onClick}
    >
      <ContentImage image={this.props.image}/>
    </div>
  }

  styleForState = () => {
    return this.state.showOverlay ? {
      ...this.props.image.style,
      transform: "scale(1.05, 1.05)",
      transition: "0.25s ease-out"
    } : {
      ...this.props.image.style,
      transform: "scale(1.0, 1.0)",
      transition: "0.25s ease-out"
    }
  }

  onMouseOver = () => {
    if (!this.state.showOverlay) {
      this.setState(new ContentImageLinkState(true));
    }
  }

  onMouseLeave = () => {
    if (this.state.showOverlay) {
      this.setState(new ContentImageLinkState(false));
    }
  }
}