import { State } from "./State";

export class LocalizedText {
  english: string;

  constructor(
    english: string
  ) {
    this.english = english;
  }

  forLocale = (locale: State.Locale) => {
    switch (locale) {
    case State.Locale.english: return this.english;
    }
  }
}
  