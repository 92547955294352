import { PureComponent } from "react"
import { Content } from "../model/Content"
import { Dimen } from "../model/Dimen"
import { ViewModel } from "../model/ViewModel"
import { ContentImageLink } from "./content/ContentImageLink"

export class StreakIconProps {
    size: number;
    center: boolean;
    onClickStreakIcon: () => void;

    constructor(
        size: number,
        center: boolean,
        onClickStreakIcon: () => void
    ) {
        this.size = size;
        this.center = center;
        this.onClickStreakIcon = onClickStreakIcon;
    }
}

export class StreakIcon extends PureComponent<StreakIconProps> {
    render = () => {
        return <ContentImageLink
        image={new Content.Image(
          Dimen.CornerRadius.zero,
          ViewModel.ImageSource.streakIcon,
          {},
          this.props.center,
          Content.Size.withValues(this.props.size, this.props.size) 
        )}
        onClick={this.props.onClickStreakIcon}
      />
    }
}