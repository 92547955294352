import { PureComponent } from "react";
import { Dimen } from "../model/Dimen";
import { State } from "../model/State";
import { ViewModel } from "../model/ViewModel";
import { ContentText } from "./content/ContentText";
import { ContentVideo } from "./content/ContentVideo";

export class Home01WelcomeProps {
    contentMode: State.ContentMode;
    locale: State.Locale;

    constructor(
        contentMode: State.ContentMode,
        locale: State.Locale
    ) {
        this.contentMode = contentMode;
        this.locale = locale;
    }
}

export class Home01Welcome extends PureComponent<Home01WelcomeProps> {
  render = () => { return <div id={ViewModel.Identifier.home}>
      {this.contentForMode()}
    </div>
  }

  contentForMode = () => {
    switch (this.props.contentMode) {
      case State.ContentMode.desktop: return this.contentForDesktopMode();
      case State.ContentMode.tablet: return this.contentForTabletMode();
      case State.ContentMode.mobile: return this.contentForMobileMode();
    }
  }

  contentForDesktopMode = () => {
    const contentSectionTextVideo = ViewModel.Home01Welcome.contentSectionTextVideo(State.ContentMode.desktop)
    return <div>
      <div style={{
        ...{ 
          display: "flex",
          alignItems: "top",
          justifyContent: "space-between"
        },
        ...Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)
      }}>
      <div style={{
        ...{ 
          display: "flex-column",
          alignItems: "start"
        }
      }}>
        <div style={{
          ...{ 
            display: "flex",
            alignItems: "baseline"
          }
        }}>
          <a 
            style={{
              ...contentSectionTextVideo.textSection.title.style
            }}>
            {contentSectionTextVideo.textSection.title.localizedText.forLocale(this.props.locale)}
          </a>
          <a 
            style={{
              ...contentSectionTextVideo.textSection.subtitles[0].style,
              ...Dimen.Margin.left(Dimen.Spacer.ofSize(Dimen.Size._03))
            }}>
            {contentSectionTextVideo.textSection.subtitles[0].localizedText.forLocale(this.props.locale)}
          </a>
        </div>
        {
            contentSectionTextVideo.textSection.subtitles.map((subtitle, index) =>
              index > 0 ?
              <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
                <ContentText 
                  contentText={subtitle}
                  locale={this.props.locale}
                />
              </div> : undefined
            )
          }
      </div>    
      <div style={{
        ...Dimen.Margin.left(Dimen.Margin.contentSectionSubtitle),
        ...Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)
      }}>
      <ContentVideo 
        video={contentSectionTextVideo.video}
        locale={this.props.locale}
      />
      </div>
      </div>
    </div>
  }

  contentForTabletMode = () => {
    return this.contentForMobileMode()
  }

  contentForMobileMode = () => {
    const contentSectionTextVideo = ViewModel.Home01Welcome.contentSectionTextVideo(State.ContentMode.mobile)
    return <div>
      <a 
        style={{
          ...contentSectionTextVideo.textSection.title.style
        }}>
        {contentSectionTextVideo.textSection.title.localizedText.forLocale(this.props.locale)}
      </a>
      <a 
        style={{
          ...contentSectionTextVideo.textSection.subtitles[0].style,
          ...Dimen.Margin.left(Dimen.Spacer.ofSize(Dimen.Size._03))
        }}>
        {contentSectionTextVideo.textSection.subtitles[0].localizedText.forLocale(this.props.locale)}
      </a>
      <div style={{
        ...Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)
      }}>
        <ContentVideo 
          video={contentSectionTextVideo.video}
          locale={this.props.locale}
        />
      </div>
      {
          contentSectionTextVideo.textSection.subtitles.map((subtitle, index) =>
            index > 0 ?
            <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
              <ContentText 
                contentText={subtitle}
                locale={this.props.locale}
              />
            </div> : undefined
          )
        }
    </div>    
  }
}
