import React from "react";
import { Content } from "../../model/Content";
import { State } from "../../model/State";

export class ContentTextProps {
  contentText: Content.Text;
  locale: State.Locale;
  id?: string;

  constructor(
    contentText: Content.Text,
    locale: State.Locale,
    id?: string
  ) {
    this.contentText = contentText;
    this.locale = locale;
    this.id = id;
  }
}

export class ContentText extends React.PureComponent<ContentTextProps> {
  render = () => {
    return <a 
      id={this.props.id}
      style={this.props.contentText.style}>
        {this.props.contentText.localizedText.forLocale(this.props.locale)}
      </a>
  }
}