import React from "react";
import { Content } from "../../model/Content";

export class ContentImageProps {
  image: Content.Image

  constructor(image: Content.Image) {
    this.image = image;
  }
}

export class ContentImage extends React.PureComponent<ContentImageProps> {
  render = () => {
    return <img 
      style={this.props.image.style}
      src={this.props.image.source}
    />
  }
}