import React from "react";
import { ContentText } from "./ContentText";
import { Dimen } from "../../model/Dimen";
import { State } from "../../model/State";
import { ViewModel } from "../../model/ViewModel";
import { Content } from "../../model/Content";

export class ContentSectionTextProps {
  textSection: ViewModel.ContentSectionText;
  locale: State.Locale;
  id?: string;

  constructor(
    content: ViewModel.ContentSectionText,
    locale: State.Locale,
    id?: string
  ) {
    this.textSection = content;
    this.locale = locale;
    this.id = id;
  }
}

export class ContentSectionText extends React.PureComponent<ContentSectionTextProps> {
  render = () => {
    return <div>
      {this.props.id != null ? <div id={this.props.id!}></div> : <div></div> }
      <ContentText
        contentText={this.props.textSection.title}
        locale={this.props.locale}
      />
      {ContentSectionText.subtitlesContent(this.props.textSection.subtitles, this.props.locale)}
    </div>
  }
}

export namespace ContentSectionText {
  export const subtitlesContent = (subtitles: Content.Text[], locale: State.Locale) => {
    return subtitles.map(subtitle =>
      <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
        <ContentText 
          contentText={subtitle}
          locale={locale}
        />
      </div>
    )
  }
}