import { PureComponent } from "react";
import { Dimen } from "../../model/Dimen";
import { State } from "../../model/State";
import { ViewModel } from "../../model/ViewModel";
import { ContentSectionText } from "./ContentSectionText";
import { ContentText } from "./ContentText";
import { ContentVideo } from "./ContentVideo";

export class ContentSectionTextVideoProps {
  contentSectionTextVideo: ViewModel.ContentSectionTextVideo;
  contentMode: State.ContentMode;
  locale: State.Locale;

  constructor(
    contentSectionTextVideo: ViewModel.ContentSectionTextVideo,
    contentMode: State.ContentMode,
    locale: State.Locale
  ) {
    this.contentSectionTextVideo = contentSectionTextVideo;
    this.contentMode = contentMode;
    this.locale = locale;
  }
}

export class ContentSectionTextVideo extends PureComponent<ContentSectionTextVideoProps> {
  render = () => { return this.contentForMode(); }

  contentForMode = () => {
    switch (this.props.contentMode) {
      case State.ContentMode.desktop: return this.contentForDesktopMode();
      case State.ContentMode.tablet: return this.contentForTabletMode();
      case State.ContentMode.mobile: return this.contentForMobileMode();
    }
  }

  contentForDesktopMode = () => {
    return <div>
      <ContentText 
        contentText={this.props.contentSectionTextVideo.textSection.title}
        locale={this.props.locale}
      />
      <div style={{
        ...{ 
          display: "flex",
          alignItems: "top",
          justifyContent: "space-between"
        },
        ...Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)
      }}>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle.negative())}>
          {ContentSectionText.subtitlesContent(this.props.contentSectionTextVideo.textSection.subtitles, this.props.locale)}
        </div>
        <div style={Dimen.Margin.left(Dimen.Margin.contentSectionSubtitle)}>
        <ContentVideo 
          video={this.props.contentSectionTextVideo.video}
          locale={this.props.locale}
        />
        </div>
      </div>
    </div>
    // return <div style={{ 
    //   display: "flex",
    //   alignItems: "top"
    // }}>
    //   <ContentText 
    //     contentText={this.props.contentSectionTextVideo.textSection.title}
    //     locale={this.props.locale}
    //   />
    //   <div style={{
    //     display: "flex-column",
    //     alignItems: "start"
    //   }}>
    //     <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
    //       {ContentSectionText.subtitlesContent(this.props.contentSectionTextVideo.textSection.subtitles, this.props.locale)}
    //     </div>
    //   </div>
    //   <ContentVideo 
    //     video={this.props.contentSectionTextVideo.video}
    //     locale={this.props.locale}
    //   />
    // </div>
  }

  contentForTabletMode = () => {
    return this.contentForDesktopMode()
    // return <div>
    //   <ContentText 
    //     contentText={this.props.contentSectionTextVideo.textSection.title}
    //     locale={this.props.locale}
    //   />
    //   <div style={{ 
    //     display: "flex",
    //     alignItems: "top"
    //   }}>
    //     <div style={Dimen.Margin.top(new Dimen.Spacer(-8))}>
    //       {ContentSectionText.subtitlesContent(this.props.contentSectionTextVideo.textSection.subtitles, this.props.locale)}
    //     </div>
    //   <div style={{
    //     ...Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle),
    //     ...Dimen.Margin.left(Dimen.Margin.contentSectionSubtitle)
    //   }}>
    //     <ContentVideo 
    //       video={this.props.contentSectionTextVideo.video}
    //       locale={this.props.locale}
    //     />
    //   </div>
    // </div></div>

    // return <div>
    //   <ContentText 
    //     contentText={this.props.contentSectionTextVideo.textSection.title}
    //     locale={this.props.locale}
    //   />
    //   <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
    //     <ContentVideo 
    //       video={this.props.contentSectionTextVideo.video}
    //       locale={this.props.locale}
    //     />
    //   </div>
    //   <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
    //     {ContentSectionText.subtitlesContent(this.props.contentSectionTextVideo.textSection.subtitles, this.props.locale)}
    //   </div>
    // </div>
  }

  contentForMobileMode = () => {
    return <div>
      <ContentText 
        contentText={this.props.contentSectionTextVideo.textSection.title}
        locale={this.props.locale}
      />
      <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
        <ContentVideo 
          video={this.props.contentSectionTextVideo.video}
          locale={this.props.locale}
        />
      </div>
      <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
        {ContentSectionText.subtitlesContent(this.props.contentSectionTextVideo.textSection.subtitles, this.props.locale)}
      </div>
    </div>
  }
}