import React from "react";
import { Content } from "../../model/Content";
import { State } from "../../model/State";

export class ContentTextLinkProps {
  contentText: Content.Text;
  locale: State.Locale
  onClick: () => void;

  constructor(
    contentText: Content.Text,
    locale: State.Locale,
    onClick: () => void
  ) {
    this.contentText = contentText;
    this.locale = locale;
    this.onClick = onClick;
  }
}

export class ContentTextLinkState {
    showUnderline: boolean

    constructor(showUnderline: boolean) {
        this.showUnderline = showUnderline;
    }
}

export class ContentTextLink extends React.Component<ContentTextLinkProps, ContentTextLinkState> {
  state = new ContentTextLinkState(false);
  
  render = () => {
    return <a
        style={this.styleForState()}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        onClick={this.props.onClick}
      >
        {this.props.contentText.localizedText.forLocale(this.props.locale)}
      </a>
  }

  styleForState = () => {
      return this.state.showUnderline ? {
          ...this.props.contentText.style,
          textDecoration: "underline"
      } : this.props.contentText.style
  }

  onMouseOver = () => {
      if (!this.state.showUnderline) {
        this.setState(new ContentTextLinkState(true));
      }
  }

  onMouseLeave = () => {
    if (this.state.showUnderline) {
      this.setState(new ContentTextLinkState(false));
    }
  }
}