import React from "react";
import { ViewModel } from "../model/ViewModel";
import { State } from "../model/State";
import { ContentSectionText } from "./content/ContentSectionText";

export class PrivacyProps {
  contentMode: State.ContentMode;
  locale: State.Locale;

  constructor(
    contentMode: State.ContentMode,
    locale: State.Locale
  ) {
    this.contentMode = contentMode;
    this.locale = locale;
  }
}

export class Privacy extends React.PureComponent<PrivacyProps> {
  render = () => {
    return <div>
        <ContentSectionText 
          textSection={ViewModel.Privacy.content(this.props.contentMode)}
          locale={this.props.locale}
          id={ViewModel.Identifier.privacy}
        />
      </div> 
  }
}