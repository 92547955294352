import { Component } from 'react';
import { Dimen } from './model/Dimen';
import { Footer } from './components/Footer';
import { Privacy } from './components/Privacy';
import { State } from './model/State';
import { Support } from './components/Support';
import { ViewModel } from './model/ViewModel';
import { Home01Welcome } from './components/Home01Welcome';
import { Home02AddDaylies } from './components/Home02AddDaylies';
import { Home03MakeDayleyEntries } from './components/Home03AddEntries';
import { Home04GoStreaking } from './components/Home04GoStreaking';
import { Header } from './components/Header';

export type AppProps = {};
export type AppState = State.Value;

class App extends Component<AppProps, AppState> {
  state: AppState = new State.Value();
  
  componentDidMount = () => {
    this.setState(new State.Value(
        State.ContentMode.forWindowWidth(window.innerWidth),
        State.HeaderMode.normal,
        State.Locale.forLocale(global.navigator.language)
      )
    )
    window.addEventListener(ViewModel.Event.resize, this.onResize);
    window.addEventListener(ViewModel.Event.scroll, this.onScroll);
  }

  onResize = () => {
    const newState = this.state.updateForWindowWidth(window.innerWidth);
    this.updateStateIfNeeded(newState);
  }

  onScroll = () => {
    const header = document.getElementById(ViewModel.Identifier.header.valueOf());
    const newState = this.state.updateHeaderMode(window.pageYOffset, header?.offsetHeight ?? 0);
    this.updateStateIfNeeded(newState);
  }

  updateStateIfNeeded(state: State.Value) {
    if (state !== this.state) {
      this.setState(state);
    }
  }

  render = () => {
    return <div id={ViewModel.Identifier.root}>
      <Header
        contentMode={this.state.contentMode}
        headerMode={this.state.headerMode}
        locale={this.state.locale}
        onClickHomeButton={onClickHeaderHomeButton}
        onClickSupportButton={onClickHeaderSupportButton}
        onClickPrivacyButton={onClickHeaderPrivacyButton}
        onClickAppStoreButton={onClickHeaderAppStoreButton}
      />
      <div style={{
        width: "100%",
        boxSizing: "border-box",
        ...Dimen.Padding.horizontal(Dimen.Padding.content),
        ...Dimen.Padding.bottom(Dimen.Padding.content)
      }}>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionSubtitle)}>
          <Home01Welcome 
            contentMode={this.state.contentMode}
            locale={this.state.locale}
          />
        </div>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <Home02AddDaylies 
            contentMode={this.state.contentMode}
            locale={this.state.locale}
          />
        </div>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <Home03MakeDayleyEntries
            contentMode={this.state.contentMode}
            locale={this.state.locale}
          />
        </div>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <Home04GoStreaking
            contentMode={this.state.contentMode}
            locale={this.state.locale}
          />
        </div>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <Support 
            contentMode={this.state.contentMode}
            locale={this.state.locale}
            onClickSupportButton={onClickSupportButton}
          />
        </div>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <Privacy
            contentMode={this.state.contentMode}
            locale={this.state.locale}
          />
        </div>
        <div style={Dimen.Margin.top(Dimen.Margin.contentSectionTitle)}>
          <Footer 
            contentMode={this.state.contentMode}
            locale={this.state.locale}
            onClickCopyrightButton={onClickCopyrightButton}
          />
        </div>
      </div>
    </div>
  }
}

const onClickHeaderHomeButton = () => {
  scrollToElementWithId(ViewModel.Identifier.home);
}

const onClickHeaderSupportButton = () => {
  scrollToElementWithId(ViewModel.Identifier.support);
}

const onClickHeaderPrivacyButton = () => {
  scrollToElementWithId(ViewModel.Identifier.privacy);
}

const onClickHeaderAppStoreButton = () => {
  window.location.href='https://apps.apple.com/us/app/streak/id1070975673'
}

const onClickSupportButton = () => {
  window.location.href="mailto:mail@letsgostreaking.app"
}

const onClickCopyrightButton = () => {
  window.location.href='https://jaredbourgeois.com'
}

const scrollToElement = (element: HTMLElement) => {
  element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

const scrollToElementWithId = (id: string) => {
  let element = document.getElementById(id)
  if (element != null) {
    scrollToElement(document.getElementById(id)!)
  }
}

export default App;
